.tabbedQuickLinksWrapper {
  display: grid;
  grid-template-rows: repeat(2, auto);
  width: 100%;
  max-width: 1600px;
  padding: var(--ds-spacing-xs);
  padding-right: 0;
  background-color: var(--background-color);

  @media (--ds-breakpoint-sm) {
    padding-right: var(--ds-spacing-xs);
  }

  @media (--ds-breakpoint-md) {
    padding: var(--ds-spacing-md) var(--ds-spacing-lg);
  }

  @media (--ds-breakpoint-lg) {
    padding: var(--ds-spacing-sm) var(--ds-spacing-lg);
  }

  & [data-test='advanced-quick-links'] {
    padding: var(--ds-spacing-3xs) 0 0 0;
    overflow: hidden;
    align-items: start;
  }

  /* 
    We need to override the default width of the advanced quick links wrapper, quick links wrapper and the carousel wrapper here
    to enable the behaviour described in the comment below. Overwriting the justify-content also ensures the quick links are left-aligned,
    as per the tabbedAdvancedQuickLinks design.
  */
  & [data-test='advanced-quick-links'] > * {
    @media (--ds-breakpoint-lg) {
      width: 100%;
    }
  }

  & [data-test='advanced-quick-links'] {
    @media (--ds-breakpoint-lg) {
      & [data-test='carousel'] {
        width: 100%;

        & [data-test='carousel-list'] {
          width: 100%;
          justify-content: flex-start;
        }
      }
    }

    & [data-test='carousel'] {
      & [data-test='carousel-list'] li {
        width: 100px;

        @media (--ds-breakpoint-sm) {
          width: 142px;
        }

        @media (--ds-breakpoint-md) {
          width: 191px;
        }

        @media (--ds-breakpoint-lg) {
          /* 
          At the 1200px breakpoint, we need to set the width as a percentage to allow the quick link cards
          to scale in size and ensure there are always 8 cards visible (and not in a carousel).
          */
          width: 11.9%;
        }

        @media (--ds-breakpoint-xl) {
          width: 184px;
        }
      }
    }
  }

  &.hasTitle {
    padding: var(--ds-spacing-md) var(--ds-spacing-xs);
    padding-right: 0;

    & [data-test='tabs-list'] {
      margin-top: 0;
    }

    @media (--ds-breakpoint-sm) {
      padding-right: var(--ds-spacing-xs);
    }

    @media (--ds-breakpoint-md) {
      padding: var(--ds-spacing-md) var(--ds-spacing-lg);
    }

    @media (--ds-breakpoint-lg) {
      padding: var(--ds-spacing-lg);
    }
  }

  .title {
    color: var(--title-color);
    margin: 0;
    padding-bottom: var(--ds-spacing-xs);
    padding-left: var(--ds-spacing-3xs);

    &.center {
      text-align: center;
    }
  }
}
